const CleanMainMenuConfig = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "apps",
    route: "/apps",
    pages: [
      {
        sectionTitle: "Student",
        route: "/student",
        svgIcon: "media/icons/duotune/communication/com006.svg",
        fontIcon: "bi-person",
        sub: [
          {
            heading: "Student listing",
            route: "/apps/students/students-listing",
          },
        ],
      },
      {
        sectionTitle: "Groups",
        route: "/groups",
        svgIcon: "media/icons/duotune/communication/com014.svg",
        fontIcon: "bi-calendar3-event",
        sub: [
          {
            heading: "Group listing",
            route: "/apps/groups/groups-listing",
          },
        ],
      },
      {
        sectionTitle: "Teachers",
        route: "/teachers",
        svgIcon: "media/icons/duotune/communication/com013.svg",
        fontIcon: "bi-printer",
        sub: [
          {
            heading: "Teacher listing",
            route: "/apps/teachers/teachers-listing",
          },
        ],
      },
    ],
  },
  {
    heading: "Settings",
    route: "/settings",
    pages: [
      {
        sectionTitle: "SMS Notifications",
        route: "/sms",
        svgIcon: "media/icons/duotune/communication/com003.svg",
        fontIcon: "bi-chat-left",
        sub: [
          {
            heading: "SMS Notifications Listings",
            route: "/apps/sms/notifications-listing",
          },
        ],
      },
    ],
  },
];

export default CleanMainMenuConfig;
