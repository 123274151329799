import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "aside-secondary d-flex flex-row-fluid"
}
const _hoisted_2 = {
  class: "aside-workspace my-5 p-5",
  id: "kt_aside_wordspace"
}
const _hoisted_3 = { class: "d-flex h-100 flex-column" }
const _hoisted_4 = {
  class: "flex-column-fluid hover-scroll-y",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "true",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-wrappers": "#kt_aside_wordspace",
  "data-kt-scroll-dependencies": "#kt_aside_secondary_footer",
  "data-kt-scroll-offset": "0px"
}
const _hoisted_5 = { class: "tab-content" }
const _hoisted_6 = {
  class: "tab-pane fade",
  id: "kt_aside_nav_tab_projects",
  role: "tabpanel"
}
const _hoisted_7 = { class: "m-0" }
const _hoisted_8 = { class: "d-flex mb-10" }
const _hoisted_9 = { class: "flex-shrink-0 ms-2" }
const _hoisted_10 = {
  type: "button",
  class: "btn btn-icon btn-bg-light btn-active-icon-primary btn-color-gray-400",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_11 = { class: "svg-icon svg-icon-2" }
const _hoisted_12 = {
  class: "tab-pane fade active show",
  id: "kt_aside_nav_tab_menu",
  role: "tabpanel"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Dropdown1 = _resolveComponent("Dropdown1")!
  const _component_KTMenu = _resolveComponent("KTMenu")!

  return (_ctx.asideSecondaryDisplay)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("button", _hoisted_10, [
                          _createElementVNode("span", _hoisted_11, [
                            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen031.svg" })
                          ])
                        ]),
                        _createVNode(_component_Dropdown1)
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _createVNode(_component_KTMenu)
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}