
import { defineComponent } from "vue";
import KTMenu from "@/layout/aside/Menu.vue";
import Dropdown1 from "@/components/dropdown/Dropdown1.vue";
import {
  minimizedAsideSecondary,
  asideSecondaryDisplay,
  minimizationEnabled,
} from "@/core/helpers/config";
import { getIllustrationsPath } from "@/core/helpers/assets";

export default defineComponent({
  name: "kt-aside-secondary",
  components: {
    KTMenu,
    Dropdown1,
  },
  setup() {
    return {
      minimizedAsideSecondary,
      asideSecondaryDisplay,
      minimizationEnabled,
      getIllustrationsPath,
    };
  },
});
